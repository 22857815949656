<template>
  <tp-modal name="tranfer-handling-modal" max-width="480px">
    <v-card class="modal">
      <v-card-title class="pb-4">
        <div class="font-weight-bold">Chuyển xử lý</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="text-body-1">
        Chuyển sản phẩm sang bảo hành hoặc sửa chữa nội bộ nếu phát hiện lỗi cần
        xử lý trong quá trình kiểm tra.
      </v-card-text>

      <v-row class="mx-3 pb-6">
        <v-col class="py-0">
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="card-option text-h6 d-flex align-center justify-center py-10"
              :class="hover ? 'cyan lighten-4' : 'cyan lighten-5'"
              flat
              @click="handleTranfer(1)"
            >
              Bảo hành
            </v-card>
          </v-hover>
        </v-col>
        <v-col class="py-0">
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="card-option text-h6 d-flex align-center justify-center py-10"
              :class="hover ? 'cyan lighten-4' : 'cyan lighten-5'"
              flat
              @click="handleTranfer(2)"
            >
              Sửa chữa
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  props: {
    storeName: {
      require: true,
      type: String
    }
  },
  computed: {
    offBizDetail() {
      return this.$store.getters[`${this.storeName}/offBizDetail`];
    }
  },
  methods: {
    async handleTranfer(type) {
      const {
        serial,
        id,
        branch_id,
        step,
        selected_action,
        assigned_to_id,
        supervisor_id
      } = this.offBizDetail;
      let routerName = "not-sold-warehouse_internal-guarantee-notes-create";
      if (type === 1) {
        routerName = "not-sold-warehouse_internal-guarantee-notes-create";
      } else {
        routerName = "not-sold-warehouse_internal-repair-notes-create";
        //not-sold-warehouse_internal-repair-notes-create
      }
      this.$router.push({
        name: routerName,
        query: {
          serial_number: serial.serial_number,
          store_name: this.storeName,
          offbiz_id: id,
          branch_id,
          serial_id: serial.id,
          step,
          selected_action,
          assigned_to_id,
          supervisor_id,
          go_back_route: this.$route.name
        }
      });
    },

    closeModal() {
      this.$modal.hide({ name: "tranfer-handling-modal" });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-option {
  transition: all 0.4s ease;
}
</style>
